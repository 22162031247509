import React from 'react'

import { createUseStyles } from 'react-jss'
import Cleave from 'cleave.js/react'

const useStyles = createUseStyles(theme => ({
  labelWrapper: {
    width: '100%',
    display: 'block',
  },
  labelInput: {
    backgroundColor: '#ffffff',
    border: '1px solid #DDDDDD',
    borderRadius: '20px',
    boxShadow: 'none',
    boxSizing: 'border-box',
    color: '#000',
    fontSize: 12,
    lineHeight: '18px',
    fontFamily: theme.fontFamily,
    letterSpacing: '.5px',
    padding: '17px 20px 13px 20px',
    width: '100%',
    '&::placeholder': {
      color: '#000000',
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '18px',
      letterSpacing: '0.02em',
    },
  },
  labelParagraph: {
    marginBottom: 10,
    marginTop: 20,

    '@media (max-width: 769px)': {
      marginTop: '15px !important',
    },
  },
  error: {
    '& input': {
      borderColor: '#EE2049',
    },
    '& .error': {
      display: 'flex',
      alignItems: 'center',
      fontSize: 12,
      paddingTop: 7,
      color: '#EE2049',
    },
    '& .error img': {
      width: 12,
      height: 12,
      marginTop: -2,
      marginRight: 5,
    },
  },
}))

const FormInput = ({
  labelText,
  inputName,
  inputType,
  placeholder,
  maskType,
  maskOptions,
  defaultValue,
  error,
  onChange,
  required,
  lessPadding,
  noMarginBottom,
}) => {
  const classes = useStyles()

  if (inputType === 'masked') {
    /*
      If something is "masked" we can use 3 different standardised examples by setting a
      maskType as either `credit`, `expiry` or `dob`. If you don't add one of these types,
      it will default to the parameters passed through as "maskOptions" and "placeholder"
      allowing you to use Cleave library from higher in the react component tree.
    */
    let options = maskOptions
    let placeholderMasked = placeholder

    if (maskType === 'credit') {
      options = {
        creditCard: true,
        delimiter: ' - ',
      }
      placeholderMasked = ''
    } else if (maskType === 'expiry') {
      options = {
        date: true,
        delimiter: ' / ',
        datePattern: ['m', 'y'],
      }
      placeholderMasked = 'MM / YY'
    } else if (maskType === 'date') {
      options = {
        date: true,
        delimiter: ' - ',
        datePattern: ['d', 'm', 'Y'],
      }
      placeholderMasked = labelText + ' DD - MM - YYYY'
    }

    return (
      <div
        className={`${error && classes.error}`}
        style={noMarginBottom ? {} : { marginBottom: 20 }}
      >
        <label htmlFor={inputName} className={`${classes.labelWrapper} ${error && classes.error}`}>
          <Cleave
            className={classes.labelInput}
            placeholder={placeholderMasked}
            options={options}
            name={inputName}
            value={defaultValue}
            onChange={onChange}
            required={required}
            style={lessPadding ? { padding: '9px 14px' } : {}}
          />
        </label>
        {error && (
          <div className="error">
            <img src="/themes/dollar/assets/images/errorex.png" />
            {error.join(', ')}
          </div>
        )}
      </div>
    )
  }

  return (
    <div className={`${error && classes.error}`} style={noMarginBottom ? {} : { marginBottom: 20 }}>
      <label htmlFor={inputName} className={`${classes.labelWrapper} ${error && classes.error}`}>
        <input
          placeholder={labelText}
          className={classes.labelInput}
          type={inputType}
          name={inputName}
          defaultValue={defaultValue}
          onChange={onChange}
          required={required}
          style={lessPadding ? { padding: '9px 14px' } : {}}
        />
      </label>
      {error && (
        <div className="error">
          <img src="/themes/dollar/assets/images/errorex.png" />
          {error.join(', ')}
        </div>
      )}
    </div>
  )
}

export default FormInput
