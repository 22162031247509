import React from 'react'

function SVGLuggage(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={props.height}
            width={props.width}
            viewBox="0 0 16 14"
        >
            <path
                fill={props.colorOne}
                d="M15.41 2.935c-.392-.401-.862-.602-1.41-.602h-2.571V.875c0-.232-.09-.455-.25-.62C11.018.091 10.799 0 10.57 0H5.43C5.2-.001 4.982.09 4.82.255c-.16.164-.25.388-.25.62v1.458H2c-.548 0-1.018.2-1.41.602-.394.401-.59.881-.59 1.44v7.583c0 .56.196 1.04.59 1.44C.981 13.8 1.451 14 2 14h12c.548 0 1.018-.2 1.41-.602.394-.4.59-.88.59-1.44V4.375c0-.559-.196-1.039-.59-1.44zm-5.124-.602H5.714V1.167h4.572v1.166z"
            />
        </svg>
    )
}

export default SVGLuggage
