import React from 'react'

function User(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0.14 0.13 14.65 17.87"
            height={props.height}
            width={props.width}
        >
            <path
                fill={props.colorOne}
                d="M7.46 8.66a7.3 7.3 0 017.33 7.47v1.46c0 .27-.27.4-.4.4H.53c-.26 0-.4-.26-.4-.4v-1.46a7.38 7.38 0 017.33-7.47zm0-8.53c2.1 0 3.8 1.73 3.8 3.86s-1.7 3.87-3.8 3.87c-2.09 0-3.79-1.73-3.79-3.87S5.37.13 7.47.13z"
            />
        </svg>
    )
}

export default User
