import React from 'react'

function Play(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 0 20 20"
            height={props.height}
            width={props.width}
            fill={props.colorOne}
        >
            <title></title>
            <desc></desc>
            <defs></defs>
            <g fill={props.colorOne} fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1">
                <g
                    fill={props.colorOne}
                    id="Icons-AV"
                    transform="translate(-168.000000, -85.000000)"
                >
                    <g id="play-circle-outline" transform="translate(168.000000, 85.000000)">
                        <path
                            d="M8,14.5 L14,10 L8,5.5 L8,14.5 L8,14.5 Z M10,0 C4.5,0 0,4.5 0,10 C0,15.5 4.5,20 10,20 C15.5,20 20,15.5 20,10 C20,4.5 15.5,0 10,0 L10,0 Z M10,18 C5.6,18 2,14.4 2,10 C2,5.6 5.6,2 10,2 C14.4,2 18,5.6 18,10 C18,14.4 14.4,18 10,18 L10,18 Z"
                            id="Shape"
                        ></path>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default Play
