import _ from 'lodash'
import numeral from 'numeral'

export const sanctionCovers = (quotation, models, context) => {
  const currentTrip = quotation.trips[quotation.trip]
  const bond = context.env.country == 'nz' ? 750 : 250
  const getBond = code => {
    return bond
  }

  const addGST = value => {
    value *= Math.abs(context.gst) + 1
    return value
  }

  let { mode } = quotation
  // - for checkin mode derive correct mode from partners model
  if (mode == 'checkin') {
    mode = 'default'
  }

  const coverItems = _.chain(currentTrip.options)
    .filter(
      o =>
        o['@attributes'].hasOwnProperty('liability') &&
        _.find(models.covers[mode], { code: o['@attributes'].code }),
    )
    .map(c => {
      const cover = _.find(models.covers[mode], { code: c['@attributes'].code })
      return {
        tag: cover.tag,
        title: cover.name,
        subtitle: cover.subtitle,
        cost: numeral(addGST(c['@attributes'].rate)).format('$0,0'),
        duration: cover.tax_label,
        rate_type: c['@attributes'].rate_type,
        excess: numeral(addGST(c['@attributes'].liability)).format('$0,0'),
        tooltipExcess: 'The liability amount of this package.',
        tooltipBond: 'The bond amount of this package.',
        features: cover.features,
        bond: numeral(getBond(c['@attributes'].code)).format('$0,0'),
        buttonStyle: currentTrip.cover == c['@attributes'].code ? 'yellow' : 'blue-white',
        buttonText: currentTrip.cover == c['@attributes'].code ? 'Selected' : 'Select this cover',
        id: c['@attributes'].code,
        selected: currentTrip.cover == c['@attributes'].code,
        maximumDays: c['@attributes'].maximum_days,
        bondexcessblurb: _.template(cover.bondexcessblurb)({
          excess: numeral(addGST(c['@attributes'].liability)).format('$0,0'),
          bond: numeral(getBond(c['@attributes'].code)).format('$0,0'),
        }),
      }
    })
    .value()

  // if(mode == 'default'){ // - disabled to allow all modes to have min cover. ideally you need to check the cover models for a mode specific set
  const basicCover = _.find(currentTrip.options, o => o['@attributes'].code == 'ACBSC')
  const basicCoverLiability = addGST(parseFloat(_.get(basicCover, `@attributes.liability`, 0)))

  //- derive excess from basic cover
  const excess =
    context.env.country == 'nz'
      ? _.includes([999, 1000], parseFloat(basicCoverLiability)) // - account for dollar rounding issue
        ? 1700
        : _.includes([1199, 1200], parseFloat(basicCoverLiability)) // - account for dollar rounding issue
        ? 2000
        : 2500
      : 4500

  // const mincover = _.find(models.covers[mode], { class: 'minimum' })
  // const isSelected =
  //   !_.isEmpty(currentTrip.cover) && (currentTrip.cover == mincover.code || currentTrip.cover == '')

  // if (!_.isEmpty(mincover)) {
  //   coverItems.push({
  //     tag: mincover.tag,
  //     title: mincover.name,
  //     subtitle: mincover.subtitle,
  //     cost: '$0',
  //     duration: mincover.tax_label,
  //     rate_type: _.get(coverItems, `0.rate_type`, 'rental days'),
  //     excess: numeral(excess).format('$0,0'),
  //     tooltipExcess: 'The excess amount of this package.',
  //     tooltipBond: 'The bond amount of this package.',
  //     features: mincover.features,
  //     bond: numeral(bond).format('$0,0'),
  //     buttonStyle: isSelected ? 'yellow' : 'blue-white',
  //     buttonText: isSelected ? 'Selected' : 'Select this cover',
  //     id: mincover.code,
  //     selected: isSelected || _.isEmpty(_.find(coverItems, { selected: true })),
  //     maximumDays: Number.MAX_VALUE,
  //     bondexcessblurb: _.template(mincover.bondexcessblurb)({
  //       excess: numeral(excess).format('$0,0'),
  //       bond: numeral(bond).format('$0,0'),
  //     }),
  //   })
  // }

  // }

  const selectedCover = _.find(coverItems, { selected: true })

  return { coverItems, selectedCover }
}

export const mutateQuotationWithCover = (newCover, newExtras, quotation) => {
  return {
    data: {
      ...quotation,
      trips: {
        ...quotation.trips,
        [quotation.trip]: {
          ...quotation.trips[quotation.trip],
          extras: [...newExtras],
          // cover: _.find(newCover, { selected: true }).id,
        },
      },
    },
  }
}
