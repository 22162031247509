import React from 'react'

function Child(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="12 11 28 31"
            height={props.height}
            width={props.width}
        >
            <path
                fill={props.colorOne}
                d="M16.04 20.5c2-.48 3.13 3.31 4.2 6.94.33 1.09 1.1 2 2.14 2.54a4.47 4.47 0 003.36.3c2.69-.84 5.32-1.67 7.19-1.67 1.31 0 2.25.42 2.54 1.55.78 3.04-1.87 6.4-6.16 8.18l.43 3.01a.57.57 0 01-.58.65H16.7a.58.58 0 01-.58-.65l.52-3.63A12.23 12.23 0 0112.45 31c-1.3-5.01.31-9.71 3.59-10.5zm13.82 5.72a2.25 2.25 0 012.35-1.24l5.85.91c1.24.2 2.1 1.4 1.91 2.69s-1.34 2.18-2.58 1.98l-.4-.06c-.36-2.45-1.95-3.61-4.32-3.61-1.03 0-2.18.21-3.42.53l.61-1.2zm-1.47-9.14c.94-.7 2.27-.5 2.97.44.7.95.5 2.29-.44 3l-4.48 3.35 1.95 3.7c-1.03.3-2.1.64-3.24 1a2.5 2.5 0 01-1.96-.17 2.55 2.55 0 01-1.24-1.54c-.44-1.55-1-3.5-1.66-4.96a3.42 3.42 0 013.04-1.03l5.06-3.79zM16.9 11.52a4.41 4.41 0 111.38 8.23 4.38 4.38 0 01-1.38-8.23z"
            />
        </svg>
    )
}

export default Child
