import React from 'react'
import PropTypes from 'prop-types'

function TickCircle(props) {
    return (
        <svg
            height={props.height}
            width={props.width}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 30 30"
        >
            <circle cx="15" cy="15" r="12" fill={props.colorOne} />
            <path
                d="M15 0c8.3 0 15 6.7 15 15s-6.7 15-15 15S0 23.3 0 15 6.7 0 15 0zm8.5 8.4c-.7-.7-1.7-.6-2.3.1l-8.5 9.3-2.9-3.3c-.7-.7-1.7-.7-2.3-.1-.7.7-.7 1.7-.1 2.4l4.1 4.6c.7.8 1.8.8 2.4 0l9.7-10.7c.6-.6.6-1.7-.1-2.3z"
                fill={props.colorTwo}
            />
        </svg>
    )
}

TickCircle.defaultProps = {
    colorOne: '#fff',
    colorTwo: '#004E95',
}

TickCircle.propTypes = {
    colorOne: PropTypes.string,
    colorTwo: PropTypes.string,
}

export default TickCircle
