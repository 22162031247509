import React from 'react'

function Wrench(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 89 83"
            height={props.height}
            width={props.width}
        >
            <path
                fill={props.colorOne}
                d="M20.3 70.34a3.47 3.47 0 01-4.88 0 3.44 3.44 0 010-4.88 3.47 3.47 0 014.89 0 3.44 3.44 0 010 4.88M80.93 22.5a1.66 1.66 0 00-2.83-1.03l-9.34 9.3-10.36-3.26-3.27-10.32 9.34-9.3a1.65 1.65 0 00-1.04-2.82 19.23 19.23 0 00-15.25 5.54 19.13 19.13 0 00-4.38 20.32c-.28.23-.56.48-.83.75L11.78 61l-.03.03a9.36 9.36 0 1013.3 13.16l29.17-31.3c.27-.27.51-.55.74-.84a19.3 19.3 0 0020.41-4.36c4.02-4 6.05-9.54 5.56-15.19"
            />
        </svg>
    )
}

export default Wrench
