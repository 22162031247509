import React from 'react'

function Shuttle(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 89 83"
            height={props.height}
            width={props.width}
        >
            <path
                fill={props.colorOne}
                d="M17 49h55V16H17v33zm55 11.5a5.5 5.5 0 10-10.99-.01A5.5 5.5 0 0072 60.5zm-44 0a5.5 5.5 0 10-10.99-.01A5.5 5.5 0 0028 60.5zM34 10h22V5H34v5zm44 6.6v49.8c0 3.06-2.5 5.53-5.58 5.53v5.54c0 3.06-2.5 5.53-5.59 5.53h-5.58a5.56 5.56 0 01-5.59-5.53v-5.54H33.33v5.54c0 3.06-2.5 5.53-5.58 5.53h-5.58a5.56 5.56 0 01-5.59-5.53v-5.54A5.56 5.56 0 0111 66.4V16.6C11 7.43 18.5 0 27.75 0h33.5C70.5 0 78 7.43 78 16.6zm8.5 5.4H84v16h2.5c1.38 0 2.5-1.19 2.5-2.67V24.67C89 23.2 87.88 22 86.5 22zm-84 0H5v16H2.5C1.12 38 0 36.81 0 35.33V24.67C0 23.2 1.12 22 2.5 22z"
            />
        </svg>
    )
}

export default Shuttle
