import React from 'react'

function SelectEmpty(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 31 30"
            height={props.height}
            width={props.width}
        >
            <path
                fill={props.colorOne}
                d="M15.67.25a14.75 14.75 0 100 29.5 14.75 14.75 0 000-29.5zm0 1.5a13.25 13.25 0 110 26.5 13.25 13.25 0 010-26.5z"
            />
        </svg>
    )
}

export default SelectEmpty
