import React from 'react'

function Cross(props) {
  return (
    <svg
      height={props.height}
      width={props.width}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.41443"
        width="23"
        height="2"
        transform="rotate(45 1.41443 0)"
        fill={props.colorOne}
      />
      <rect
        y="16.2635"
        width="23"
        height="2"
        transform="rotate(-45 0 16.2635)"
        fill={props.colorOne}
      />
    </svg>
  )
}

export default Cross
